import React from 'react';
import {withStyles} from "@material-ui/core/styles";

const styles = () => ({
    content__header: {
        padding: "50px 0"
    }
})

function Header(){
    return null;
}

function Body(){
    return null;
}

class MainWrap extends React.Component{
    static Header = Header;
    static Body = Body;

    render(){
        const { children } = this.props;
        const header = children.find(child => child.type === Header)
        const body = children.find(child => child.type === Body)

        const {
            content__header,
            content__body,
        } = this.props.classes

        return (
            <>
                <div className={content__header}>
                    { header ? header.props.children : null }
                </div>
                <div className={content__body}>
                    { body ? body.props.children : null }
                </div>
            </>
        )
    }
}

export default withStyles(styles)(MainWrap);
