import React, { useContext } from "react"
import { makeComponentStyles } from "../components/mixins"
import Layout from "../components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import { Typography, Grid } from "@material-ui/core"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { graphql } from "gatsby"
import { getStrapiContentByLang } from "../heplers"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  video_header: {
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    paddingBottom: "24px",
    [breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "21px",
      paddingBottom: "12px",
    }
  },
  video_padding: {
    paddingBottom: "43px",
    [breakpoints.down("md")]: {
      paddingBottom: "30px"
    }
  },
  video_parameters: {
    width: "780px",
    height: "470px",
    [breakpoints.down("md")]: {
      width: "600px",
      height: "320px",
    },
    '@media (max-width: 600px)': {
      width: "290px",
      height: "174px",
    },
  }
})

const VideoGallery = ({ data }) => {

  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();

  const {
    Videos,
    VideoSEO,
  } = getStrapiContentByLang(data.allStrapiVideo.edges, language);

  const {
    video_header,
    video_padding,
    video_parameters,
  } = makeComponentStyles(styles);

  return (
    <div>
      <Seo title={VideoSEO?.TitleSEO}
           description={VideoSEO?.DescriptionSEO}
           keywords={VideoSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          <Container mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {intl.formatMessage({ id: 'videogallery' })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                {
                  Videos.map(({ title, url }) => (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={video_header}>{title}</Typography>
                      </Grid>
                      <Grid className={video_padding} container justifyContent="center" xs={12}>
                        <iframe className={video_parameters} src={'https://www.youtube.com/embed/' + url.split('/').pop()}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                      </Grid>
                    </Grid>
                  ))
                }
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default VideoGallery
export const pageQuery = graphql`{
  allStrapiVideo {
  edges {
      node {
        Videos {
          title
          url
        }
        locale
        localizations {
          id
        }
        strapiId
        id
        VideoSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        } 
      }
    }
  }  
}`